@use "../../styles/_shadows";

.divider {
  width: 100%;
  height: 1px;
  background-color: rgb(202, 202, 202);
  margin: 32px 0;
}

.tooltip {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid gray;
  font-size: 12px;
  margin-left: 8px;
}

.actuatorDetails {
  max-width: 54rem;
  margin: auto;
  padding: 32px 16px;

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 32px;

    span {
      cursor: pointer;
      margin-right: 16px;
      color: var(--dark-grey);
      font-weight: 700;
    }
    h2 {
      color: var(--dark-grey);
      font-weight: 700;
    }
  }

  .details {
    h3 {
      margin-bottom: 1rem;

      .icon {
        font-size: 18px;
      }
    }

    hr {
      margin: 2rem 0 !important;
    }

    .history {
      .noHistory {
        margin-top: 1rem;
        color: var(--dark-grey);
        text-align: center;
      }
      .loader {
        margin-top: 1rem;
        text-align: center;
      }
    }
  }
}
