.logs {
  padding: 2rem 12px;

  h1 {
    color: var(--light-grey);
  }

  section {
    margin-top: 3rem;

    .h2 {
      margin-bottom: 1rem;
      color: var(--dark-grey);
    }

    pre {
      background-color: #f6f7f8 !important;
      padding: 1rem;
      overflow: auto;
      word-wrap: normal;
      overflow-wrap: normal;
      white-space: pre;
      line-height: 140%;
    }
  }
}
