.averageCard {
  position: relative;
  display: flex;
  align-items: center;
  justify-self: center;
  flex-direction: column;
  flex-wrap: wrap;
  width: 301px;
  min-height: 248px;
  padding: 20px 12px 12px;
  transition: background-color 0.1s cubic-bezier(0.4, 0, 0.2, 1);
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0px -1px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);

  text-align: center;

  @media only screen and (max-width: 639px) {
    width: 100%;
  }

  header {
    width: 100%;

    .title {
      display: flex;
      align-items: center;
      text-align: left;
      font-size: 1.2rem !important;
      color: var(--dark-grey);

      span {
        margin-right: 0.25rem;
      }
    }
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding: 1rem;

    .display {
      font-size: 1.4rem;
      margin-bottom: 1rem;

      .value {
        font-size: 2rem;
        font-weight: 600;
      }
    }
  }

  .isLoading {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: rgb(0 0 0 / 12%);
  }
}
