.parameters {
  display: flex;
  gap: 2rem;
  flex-direction: column;
  padding: 1rem;
  max-width: 820px;
  margin: auto;

  .state {
    margin-top: 1rem;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 0px -1px 1px -1px rgb(0 0 0 / 20%),
      0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);

    .title {
      margin-bottom: 1rem;
      color: var(--dark-grey);
    }
  }

  section {
    display: flex;
    gap: 1rem;
    flex-direction: column;
  }
}

.desc {
  padding: 16px 0;
}
.buttons {
  padding-top: 16px;
  padding-bottom: 8px;
  display: flex;
  align-items: center;

  button:last-child {
    margin-left: 8px;
  }
}
