.container {
  .mask {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000, $alpha: 0.4);
  }

  .bell {
    display: inline-block;
    position: relative;
  }

  .notificationAlert {
    background-color: #fa3e3e;
    border-radius: 100%;
    color: white;
    z-index: 1;

    padding: 1px 3px;
    font-size: 10px;

    position: absolute;
  }
}

.notificationEmpty {
  display: none;
}

.container:hover .notificationAlert {
  transition: filter 0.2s;
  filter: brightness(0.8);
}

.container:hover .bell {
  transition: filter 0.2s;
  filter: brightness(0.8);
}

.notification {
  header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: black;

    h2 {
      font-size: 2rem;
    }

    .closeButton {
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }

  position: fixed;
  top: 0;
  right: -330px;
  bottom: 0;
  width: 330px;
  max-width: 100%;
  z-index: 9999;
  background: #fff;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  padding: 24px 48px 120px 24px;
  overflow: hidden;
  transition: right 0.5s;

  &.open {
    right: 0;
  }

  .content {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    align-items: center;

    &.empty {
      justify-content: center;
    }

    aside {
      display: flex;
      flex-direction: column;
      gap: 0.4rem;
      margin-top: 2rem;

      img {
        filter: grayscale(40%);
        max-width: 100%;
      }
    }
  }

  .actuatorCard {
    color: var(--green);
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0px -1px 1px -1px rgb(0 0 0 / 20%),
      0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    font-weight: 600;
  }
  .notificationCard {
    color: var(--dark-grey);
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0px -1px 1px -1px rgb(0 0 0 / 20%),
      0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    font-weight: 600;
  }

  .noNotifications {
    display: flex;
    justify-content: center;
    color: var(--dark-grey);
    font-size: 1.5rem;
  }
}
.actuatorsButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;

  button {
    display: flex;

    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    width: 234px;
    text-align: center;

    & + button {
      margin-top: 0.5rem;
    }
  }
}

.dialog {
  z-index: 999999999999999999999999;
}

.h3 {
  color: #000;
  font-weight: 600;

  margin-top: 2rem;
  margin-bottom: 0.75rem;
}
