.dialog {
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;

  .dialogBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0.25rem;
    background-color: var(--white);
    padding: 0.5rem 1rem;

    h2 {
      padding-left: 1rem;
      padding-top: 1rem;
      margin-bottom: 0.5rem;
    }
  }
}
