.title {
  width: 640px;
  padding: 2rem 12px 0rem 12px;
  margin: auto;

  @media only screen and (max-width: 639px) {
    width: 100%;
    padding: 2rem 24px;
  }

  @media only screen and (min-width: 960px) {
    width: 960px;
  }
}

.cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  width: 640px;
  padding: 2rem 12px;
  margin: auto;

  @media only screen and (max-width: 639px) {
    width: 100%;
    padding: 2rem 24px;
  }

  @media only screen and (min-width: 960px) {
    width: 960px;
  }
}

.actuators {
  width: 640px;
  height: 416px;
  padding: 2rem 12px;
  margin: auto;

  @media only screen and (max-width: 490px) {
    height: 624px;
  }

  @media only screen and (max-width: 639px) {
    width: 100%;
    padding: 2rem 24px;
  }

  @media only screen and (min-width: 960px) {
    width: 960px;
    height: 208px;
  }

  position: relative;
  display: flex;
  align-items: center;
  justify-self: center;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 1200px;
  padding: 20px 20px 12px;
  transition: background-color 0.1s cubic-bezier(0.4, 0, 0.2, 1);
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0px -1px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.container {
  @media only screen and (min-width: 960px) {
    width: 912px;
    height: 98%;
  }

  @media only screen and (max-width: 639px) {
    width: 100%;
    padding: 2rem 24px;

    flex-direction: column;
  }

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1;
  align-items: center;
  margin: auto;

  padding: 2rem 12px;
  border-radius: 10px;
  box-shadow: 0px -1px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.image {
  max-height: 56px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  padding: 0 8px;
}
