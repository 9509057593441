.container {
  .mask {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000, $alpha: 0.4);
  }

  .drawer {
    header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .closeButton {
        position: absolute;
        top: 20px;
        right: 20px;
      }
    }

    position: fixed;
    top: 0;
    left: -330px;
    bottom: 0;
    width: 330px;
    max-width: 100%;
    z-index: 9999;
    background: #fff;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 24px 48px 120px 24px;
    overflow: hidden;
    transition: left 0.5s;

    &.open {
      left: 0px;
    }

    .content {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
    }

    nav {
      display: flex;
      flex-direction: column;
      gap: 0.4rem;
      margin-top: 2rem;

      .link {
        padding: 0.6rem 1rem;
        border-radius: 10px;
        text-decoration: none;
        color: var(--dark-grey);

        a {
          color: var(--dark-grey);
          text-decoration: none;
        }

        &:hover {
          background: var(--super-light-grey);
        }
      }
    }

    footer {
      color: var(--dark-grey);
      margin: 2rem 0;
    }
  }

  .logout {
    margin-bottom: 8px;

    button {
      padding: 8px 32px;
    }
  }
}
