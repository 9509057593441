.container {
  padding: 1rem;
}

.actuators {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 54rem;
  margin: auto;
}
.loader {
  left: 50%;
  transform: translateX(-50%);
  position: relative;
  width: 100%;
  max-width: 54rem;
  border-radius: 10px;
  height: 240px;
  margin-bottom: -252px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modeControl {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 1rem;
  margin: 0.75rem auto 2rem auto;
  max-width: 54rem;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0px -1px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);

  .title {
    color: var(--dark-grey);
    margin-bottom: 8px;
  }

  .titleLock {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.25rem;
  }

  .toggle {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .toggleItem {
      margin-left: 16px;
      padding: 16px 0;
    }
  }

  .locked {
    display: flex;
    align-items: center;
    gap: 0.6rem;
  }
}

.bodyDialog {
  margin-top: 1rem;
  text-align: center;
}

.center {
  margin-top: 32px;

  display: flex;
  align-items: center;
  justify-content: center;
}
