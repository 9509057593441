@use "../../styles/_shadows";

.login {
  display: flex;
  flex-direction: row;
  height: 100vh;
  background-color: var(--green);

  section.wrapper {
    @extend .shadow-2dp;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    max-width: 28rem;
    background-color: var(--white);
    padding: 2rem;

    header {
      display: flex;
      justify-content: center;
      align-items: center;

      h1 {
        font-size: 2rem !important;
        font-weight: 500;
        color: var(--dark-grey);
      }

      img {
        max-width: 100%;
        width: 140px;
      }
    }

    form {
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      gap: 1rem;
      padding: 2rem;
      width: 100%;
      height: 100%;

      @media only screen and (max-width: 600px) {
        padding: 0.2rem;
      }

      .action {
        width: 100%;
        button {
          float: right;
          margin-top: 1rem;
        }
      }
    }
  }

  section.info {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;
    width: 100%;
    height: 100%;

    @media only screen and (max-width: 1080px) {
      .project,
      .sponsors {
        display: none;
      }
    }

    @media only screen and (max-width: 800px) {
      display: none;
    }

    header {
      width: 100%;
      text-align: left;

      h1 {
        font-size: 3.2rem !important;
        font-weight: 700;
        color: var(--white);
        line-break: strict;
        max-width: 24rem;
      }
    }

    .project img {
      max-width: 48rem;
      width: 100%;
    }

    .sponsors {
      padding: 1rem 4rem;
      background-color: var(--white);
      border-radius: 15px;

      img {
        max-width: 38rem;
        width: 100%;
      }
    }
  }
}
