@use "../../styles/_shadows";

.reportDetails {
  max-width: 54rem;
  margin: auto;
  padding: 32px 16px;

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 32px;

    span {
      cursor: pointer;
      margin-right: 16px;
      color: var(--dark-grey);
      font-weight: 700;
    }
    h2 {
      color: var(--dark-grey);
      font-weight: 700;
    }
  }

  .analyze {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
    }

    .analyzeDetails {
      width: 100%;
      margin-top: 32px;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      border-radius: 6px;
      padding: 24px;
      @extend .shadow-3dp;

      h4 {
        width: fit-content;
        font-weight: 700;
        font-size: 16px;
        margin-top: 16px;
        margin-bottom: 8px;
      }
      span {
        font-weight: 400;
        margin-left: 8px;
      }
      strong {
        width: fit-content;
        line-height: 1.35;
        font-weight: 700;
      }
      ul,
      li {
        width: fit-content;
      }
    }
  }

  .files {
    padding-top: 32px;

    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px 16px;
      border-radius: 8px;
      margin-top: 8px;
      @extend .shadow-3dp;

      h2 {
        font-weight: 600;
        font-size: 20px !important;
      }
    }
  }

  .downloadButton {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;

    background: var(--green);
    padding: 0.5rem 1rem 0.5rem 1rem;
    border-radius: 10px;
    border: 1px;
    color: white;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }
  }
}
