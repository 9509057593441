.actuator {
  position: relative;
  display: flex;
  align-items: center;
  justify-self: center;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0.2rem;
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0px -1px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);

  .isLoading {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: rgb(0 0 0 / 12%);
  }

  .info {
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
    gap: 0.2rem;
    margin: 1rem;

    .title {
      font-weight: 600;
    }
  }

  .actuatorDetails{
    padding: 1rem;
    cursor: pointer;
  }

  .action {
    margin: 1rem;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    gap: 0.6rem;
    align-self: flex-end;

    button:disabled {
      cursor: not-allowed;
      pointer-events: all !important;
      filter: grayscale(100%);
    }

    .buttonGroup {
      display: flex;
      border: 1px solid var(--dark-grey);
      border-radius: 2px;

      .button {
        margin: 0;
        padding: 0.5rem;
        border-radius: 2px;
        position: relative;

        &:hover {
          background-color: var(--super-light-grey);
        }

        &.active {
          .stepIndicator {
            border: 1px solid var(--green);

            .step.active {
              background: var(--green);
            }
          }

          .divider {
            border-color: var(--green);
          }
        }

        .divider {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          border-left: 1px solid var(--dark-grey);
        }

        .stepIndicator {
          position: relative;
          width: 48px;
          height: 24px;
          border-radius: 2px;
          border: 1px solid var(--dark-grey);

          .step {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;

            &.active {
              background: var(--light-grey);
            }
          }
        }
      }
    }
  }
}
