.loader {
  margin: 16px 0;
}
.newReport {
  display: flex;
  justify-content: flex-end;
  padding: 2rem;
}
.containerContent {
  padding: 1rem;
  max-width: 54rem;
  margin: auto;

  .buttons {
    display: flex;
    align-items: center;
    justify-content: center;

    padding-bottom: 1rem;

    button:last-child {
      margin-left: 16px;
    }
  }

  h2 {
    margin-bottom: 2rem;
  }
}

.reportData {
  padding: 1rem;
  display: none;
}
.reportDataExpanded {
  max-width: 320px;
  padding: 1rem;
  display: block;

  .errorDate {
    text-align: center;
    color: red;
    padding-top: 1.25rem;
  }

  .date {
    display: flex;
    flex-direction: row;
    align-items: center;

    .dateInput {
      display: flex;
      flex-direction: column;
      margin-right: 16px;

      label {
        margin-top: 8px;
        font-weight: 600;
        color: #32324d;
        font-size: 0.75rem;
        line-height: 1.33;

        span {
          font-size: 0.8rem;
          color: var(--red);
        }
      }

      input {
        width: 150px;
        margin-top: 4px;

        height: 37px;
        border: 1px solid #ededf1;
        border-radius: 3px;
      }
    }
  }

  footer {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    text-align: center;
    margin-top: 1rem;

    button {
      width: 16rem;
      text-align: center;
      justify-content: center;
    }
  }
}

.center {
  margin-top: 32px;

  display: flex;
  align-items: center;
  justify-content: center;
}

input {
  height: 35.5px !important;
}
