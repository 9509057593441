:root {
  --dial-degree-offset: 45deg;
}

.container {
  display: block;
  width: 168px;
  height: 168px;
  border: 1px solid rgb(117, 117, 117);
  border-radius: 50%;
  position: relative;

  span {
    font-size: 12px;
    position: absolute;
    z-index: 5;
  }
}

.dial {
  display: block;
  width: 50%;
  height: 50%;
  background: var(--green);
  opacity: 0.75;
  border-top-left-radius: 100%;
  clip-path: polygon(40% 0, 50% 50%, 0 40%);
  transform-origin: bottom right;
  transform: rotate(calc(var(--dial-degree-offset) + var(--dial-degree)));
}

.display {
  position: absolute;
  display: flex;
  align-items: flex-end;
  top: 40%;
  left: 50%;
  transform: translate(-50%);
  font-size: 1.4rem;

  .value {
    font-size: 2rem;
    font-weight: 600;
  }
}

.N {
  top: 2px;
  left: 53%;
  margin-left: -10px;
}
.NE {
  top: 25px;
  left: 74%;
  margin-left: 5px;
}
.E {
  right: 5px;
  top: 51%;
  margin-top: -10px;
}
.SE {
  right: 13%;
  top: 69%;
  margin-top: 15px;
}
.S {
  bottom: 3px;
  left: 54%;
  margin-left: -10px;
}
.SW {
  bottom: 15%;
  left: 5%;
  margin-left: 15px;
}
.W {
  left: 3px;
  top: 52%;
  margin-top: -10px;
}
.NW {
  left: 13%;
  top: 12%;
  margin-top: 6px;
}
