@use "shadows";
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --white: #fff;
  --green: #339933;
  --yellow: #bbbe0a;
  --dark-grey: #999;
  --light-grey: #ccc;
  --blue: #0069e9;
  --red: #ef1a67;
  --super-light-grey: #efefef;
}

body {
  background: var(--gray-50);
}

#root {
  height: 100%;
}

.title {
  margin: 2rem;
  color: var(--dark-grey);
}

body,
input,
textarea,
button {
  font: 500 1rem Inter, sans-serif;
  color: var(--gray-500);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  font-family: Lexend, sans-serif;
  color: var(--gray-800);
}

h1 {
  font-size: 2rem !important;
}

h2 {
  font-size: 1.5rem !important;
}

h3 {
  font-size: 1.2rem !important;
}

strong {
  font-weight: bold !important;
}

button {
  cursor: pointer;
}

/*
    Conforme a tela vai diminuindo, 
    a estrutura de font vai diminuindo também.
    Está em % por uma questão de acessibilidade: 
    por padrão, o todo o html começa com font-size: 16px,
    mas, se o user precisa, por n motivos, aumentar
    a fonte do sistema, esse valor muda. Assim, 
    por questões de acessibilidade, é amplamente indicado 
    tudo ser o mais relativo possível.
*/

// gradually decrease font size as device size
@media (max-width: 1080px) {
  html {
    font-size: 93.75%; // 15px (default: 16px)
  }
}

@media (max-width: 768px) {
  html {
    font-size: 87.5%; // 14px (default: 16px)
  }
}

@media (max-width: 420px) {
  html {
    font-size: 81.25%; // 13px (default: 16px)
  }
}
