.chart {
  display: flex;
  align-items: center;
  width: 100%;
  height: 150px;

  p {
    margin-right: 8px;
    font-size: 14px;
    font-weight: 400;
    writing-mode: sideways-lr;
    text-orientation: sideways-left;
    color: var(--dark-grey);
  }

  canvas {
    width: 100% !important;
  }
}
