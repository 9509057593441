.hour {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 16px;

  label {
    font-weight: 600;
    color: #32324d;
    font-size: 0.75rem;
    line-height: 1.33;

    span {
      font-size: 0.8rem;
    }
  }

  .select {
    display: flex;
    align-items: center;
    border: 1px solid #dcdce4;
    border-radius: 4px;
    padding: 0 0.5rem;
    margin-top: 0.25rem;

    &:active {
      border-color: var(--blue);
      border-width: 1px;
    }

    select {
      border: none;
      height: 35.5px;
      width: 100%;
      outline: none;
      background: none;
      font-size: 0.875rem;
      line-height: 1.43;
      padding-left: 0.75rem;
      color: black;
    }
  }

  @media (max-width: 480px) {
    margin-left: 0;
    margin-top: 8px;
  }
}
