@use "../../styles/_shadows";

.report {
  @extend .shadow-3dp;
  margin: auto;
  margin-top: 0.5rem;
  border-radius: 10px;
  padding: 3rem;
  cursor: pointer;

  .reportContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .reportInfo {
      width: 100%;
      margin-right: 16px;

      .title {
        text-align: left;
        font-size: 1.2rem !important;
        color: var(--dark-grey);
      }

      .expiration {
        margin-top: 32px;
        font-size: 15px;
        color: var(--dark-grey);
      }
    }

    button {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 0.5rem;

      background: var(--green);
      padding: 0.5rem 1rem 0.5rem 1rem;
      border-radius: 10px;
      border: 1px;
      color: white;

      transition: filter 0.2s;

      &:hover {
        filter: brightness(0.9);
      }
    }

    @media (max-width: 480px) {
      flex-direction: column;

      .reportInfo {
        margin-bottom: 16px;
      }
    }
  }

  & + .container {
    margin-top: 2rem;
  }
  .reportFinished {
    display: flex;
    align-items: center;

    span {
      font-size: 40px;
      font-weight: 700;
      color: var(--green);
      margin-right: 4px;
    }
  }
  .reportLoading {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    @media (max-width: 480px) {
      align-items: baseline;
    }
  }
}
