.selectContent {
  display: flex;
  flex-direction: column;
  align-items: self-start;
  width: 100%;
}

.label {
  font-weight: 600;
  color: #32324d;
  font-size: 0.75rem;
  line-height: 1.33;

  span {
    font-size: 0.8rem;
  }
}

.select {
  margin-top: 0.25rem;
  width: 100%;
  border: 1px solid #dcdce4;
  border-radius: 4px;
  outline: none;
  background: none;
  font-size: 0.875rem;
  line-height: 1.43;
  padding: 0.75rem;
  color: black;

  &:focus {
    border-color: var(--blue);
    border-width: 2px;
  }
}
