.header {
  width: 100vw;
  top: 0;
  position: fixed;

  display: flex;
  gap: 2rem;
  padding: 1rem;
  padding-right: 2rem;
  background-color: var(--green);
  color: var(--white);
  z-index: 99;

  h1 {
    font-size: 1.4rem !important;
  }
}

.divider {
  flex: 1;
}
